@media only screen and (max-width: 600px) {
  #root {
    div.search-container {
      justify-content: unset;
      margin-bottom: 15px;
      padding-top: 15px;
    }
  }
}

// CSS for the iframe on the main website (zero.health)
.search-container-main-website {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  text-align: center;

  @media (max-width: 600px) {
    padding-top: 25px;
    padding-bottom: 10px;

    #logo-image {
      height: 50px !important;
      width: 50px !important;
    }

    #logo-container {
      padding-bottom: 5%;
    }

    #bottom-search-section {
      padding-bottom: 20px;
    }
  }

  form {
    div.fields {
      justify-content: center;

      .field {
        width: auto;
      }
    }
  }

  div.divider {
    margin: 30px;
  }

  @media (min-width: 601px) {
    #logo-container {
      padding-bottom: 5%;
      padding-top: 2%;
    }

    #logo-image {
      height: 75px !important;
      width: 75px !important;
    }
  }
}

#root {
  font-family: 'Raleway' !important;
  height: 100%;

  .search-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    form {
      div.fields {
        justify-content: center;

        .field {
          width: auto;
        }
      }
    }

    div.divider {
      margin: 30px;
    }
  }
}

.header {
  font-family: 'Raleway' !important;
}

.ui-form {
  font-family: 'Raleway' !important;
}

.ui.form input[type=text] {
    font-family: 'Raleway' !important;
}

.input {
  font-family: 'Raleway' !important;
}

.ui {
  font-family: 'Raleway' !important;
}

.huge, .ui, .header {
  font-family: 'Raleway' !important;
}