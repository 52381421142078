.pl-num-results {
  padding-left: 10px;
}

.pl-back-to-home {
  margin-bottom: 10px;
}

.pl-result {
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 30px;
  padding-top: 30px;
  border-bottom: 1px inset;
  &:hover {
    background-color: aliceblue;
  }
}


.header {
  font-family: 'Raleway' !important;
}

.ui-form {
  font-family: 'Raleway' !important;
}

.ui.form input[type=text] {
    font-family: 'Raleway' !important;
}

.input {
  font-family: 'Raleway' !important;
}

.ui {
  font-family: 'Raleway' !important;
}

.huge, .ui, .header {
  font-family: 'Raleway' !important;
}