.provider-search-container {
  margin-top: 100px;
}

.provider-search-container-from-main-site {
  margin-top: 2%;
}

.provider-search-results-container {
  min-height: 800px;
  margin-top: 15px;
}


.provider-search-results-container-main-website-view {
  min-height: 500px;
  margin-top: 15px;
}

.searchbar-field {
  width: 310px;
}

.searchbar-field.zipcode {
  width: 200px;
}

.searchbar-field.distance {
  width: 157px;
  min-width: 157px;
}

.searchbar-field.distance .selection {
  min-width: 140px;
}

.searchbar-btn {
  padding-top: 24px;
  min-width: 140px;
}

.ps-procedure-block {
  float: left;
  max-width: 120px;
  padding: 10px;
  word-break: break-word;
  hyphens: auto;
}

.ps-details-block-wrapper {
  height: 100%;
  display: inline-block;
  border-left: 1px;
  border-left-style: solid;
  border-color: rgba(211, 211, 211, 0.75);
}

.ps-details-block {
  line-height: 1.7;
  float: left;
  width: 400px;
  word-break: break-word;
  padding: 10px;
}

.ps-details-distance {
  font-style: italic;
  color: gray;
}

.ps-result-internal.hovered {
  background-color: aliceblue;
}

.ps-result {
  margin-bottom: 10px;
}

.ps-result-internal {
  min-height: 150px;
  overflow: hidden;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(211, 211, 211, 0.75);
}

.provider-search-results-column {
  height: 800px;
  min-width: 580px;
}

.provider-search-results-column-main-website {
  height: 514px;
  min-width: 500px;
}

.provider-search-results-map-column {
  min-width: 580px;
  height: 100%;
}

.ps-no-results {
  color: rgba(211, 211, 211, 0.9);
  font-style: italic;
}

.ps-back-to-home {
  margin-bottom: 10px;
}

/* ipad landscape - match semantic ui breakpoint */
@media only screen and (min-width: 992px) {
  .provider-search-top-bar {
    height: 111px;
  }

  .provider-search-results-container {
    position: absolute;
    margin-top: 15px;
    min-width: 980px;
  }

  .provider-search-results-container-main-website-view {
    position: absolute;
    margin-top: 15px;
    min-width: 980px;
  }

  .provider-search-results-column {
    min-width: 500px;
    overflow-y: scroll;
  }

  .provider-search-results-column-main-website {
    overflow-y: scroll;
    height: 514px;
  }

  .provider-search-results-map-column {
    min-width: 500px;
  }


  .ps-procedure-block {
    display: none;
  }

  .ps-details-block-wrapper {
    border-left: 0px;
  }
}

/* Match the tablet breakpoint in semantic ui */
@media only screen and (max-width: 991px) {
  .provider-search-results-map-column {
    display: none !important;
  }

  .fields {
    flex-wrap: wrap;
  }

  .field.searchbar-btn {
    display: block;
    padding-top: 33px;
    width: 100%;
  }

  .field.searchbar-btn button {
    display: block;
    width: 100%;
  }

  .searchbar-field {
    display: block !important;
    width: 100% !important;
    padding-top: 10px !important;
  }

  .mobile-procedure-text {
    display: none;
  }
}


/* Mobile styles */
@media only screen and (max-width: 600px) {
  .provider-search-container {
    margin-top: 15px;
  }

  .provider-search-container-from-main-site {
    margin-top: 30px;
  }

  .ps-procedure-block {
    display: none;
  }

  .mobile-procedure-text {
    display: block;
  }

  .provider-search-results-column {
    height: 100%;
    padding-right: 0px !important; /* override semantic ui */
    padding-left: 0px !important;
    min-width: 200px;
  }

  .provider-search-results-column-main-website {
    height: 100%;
    padding-right: 0px !important; /* override semantic ui */
    padding-left: 0px !important;
    min-width: 200px;
  }

  .ps-details-block {
    width: 100%;
  }

  .ps-result {
    height: 100%;
    margin-bottom: 15px;
  }

  .ps-result-internal {
    height: 100%;
  }

  .ps-loader {
    margin-top: 15px;
  }

  .ps-details-block-wrapper {
    border-left: 0px;
  }
}


.header {
  font-family: 'Raleway' !important;
}

.ui-form {
  font-family: 'Raleway' !important;
}

.ui.form input[type=text] {
    font-family: 'Raleway' !important;
}

.input {
  font-family: 'Raleway' !important;
}

.ui {
  font-family: 'Raleway' !important;
}

.huge, .ui, .header {
  font-family: 'Raleway' !important;
}
