.maps-container {
  height: 800px;
  width: 450px;
}

.maps-container-main-website {
  height: 500px;
  width: 90%;
}

.marker-big {
  margin-left: -11px !important;
  margin-top: -13px !important;
}

.marker-huge {
  margin-left: -22px !important;
  margin-top: -26px !important;
}
