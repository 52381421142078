.content-container {
  margin-top: 50px;
}

.ui.input.dirty {
 color: red;
}

.uploader {
  margin: 5px;
  width: 100%;
}

.ui.selection.dropdown.field {
  width: 350px;
  margin-bottom: 5px;
}

.ui.container.internal-container {
  width: 400px;
}

.validation-msg {
  position: relative;
  top: -15px;
}

div.description > div > textarea {
  display: block !important;
  width: 350px !important;
  margin: auto !important;
  margin-top: 10px !important;
  margin-bottom: 14px !important;
}

div.description > div > textarea.dirty {
  background: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
}

.file-type {
  margin-top: 0px !important;
}

.field {
  display: block;
  width: 350px;
}

.ui.card.internal-card {
  width: 400px;
}

.ui.small.header.margined {
  margin-top: 5px;
}

.ui.progress.upload-bar {
  margin: 10px;
}

.ui.form.note-text {
  margin-top: 5px;
  text-align: left;
}
